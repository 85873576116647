import { waitAuth } from "~/utils/middleware";

import { PBEnums } from "~/types/pb/enums";

import { log } from "~/composables/log";

import Abonnements = PBEnums.Abonnements;

export default defineNuxtRouteMiddleware(async () => {
    await waitAuth(Abonnements.DrivePro);

    if (!__subscription().hasDrivePro) {
        log("❌ Has no Drive pro");
        return navigateTo("/");
    }

    log("✅ Has Drive pro");
});
